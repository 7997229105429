@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

body {
  color: #fff;
  font-family: "Roboto Condensed", sans-serif;
  background-image: url(https://images.unsplash.com/photo-1613231365704-22aa7ad7cffc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1633&q=80);
  background-position: top top;
  background-size: cover;
  margin: 0;
}

.App {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background: rgb(145, 49, 177);
  background: linear-gradient(
    0deg,
    rgb(113, 49, 177, 0.7) 0%,
    rgb(107, 49, 177) 50%,
    rgba(111, 49, 177, 0.6) 100%
  );
}

.container {
  width: 100%;
  margin: 15% auto;
}
.col-4 {
  width: 25%;
  float: left;
}

h1 {
  font-size: 38px;
  text-transform: uppercase;
  line-height: 1;
}

@media (min-width: 768px) {
  .container {
    width: 1100px;
  }
  h1 {
    font-size: 58px;
  }
}

@media (max-width: 400px) {
  .container {
    margin-top: 20em;
  }
}

body {
  font-size: 10px;
  font-family: Roboto, sans-serif;
  background-color: rgb(145, 49, 177);
  margin: 0;
  display: grid;
  height: 100vh;
  place-items: center;
}

.c-checkbox {
  display: none;
}
.c-checkbox:checked + .c-formContainer .c-form {
  width: 37.5em;
}
.c-checkbox:checked + .c-formContainer .c-form__toggle {
  visibility: visible;
  opacity: 0;
  transform: scale(0.7);
}
.c-checkbox:checked + .c-formContainer .c-form__input,
.c-checkbox:checked + .c-formContainer .c-form button {
  transition: 0.2s 0.1s;
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}
.c-checkbox:not(:checked) + .c-formContainer .c-form__input:required:valid ~ .c-form__toggle::before, .c-checkbox:checked + .c-formContainer .c-form__input:required:valid ~ .c-form__toggle::before {
  content: "Thank You! 😊";
}
.c-checkbox:not(:checked) + .c-formContainer .c-form__input:required:valid ~ .c-form__toggle {
  pointer-events: none;
  cursor: default;
}

.c-formContainer,
.c-form,
.c-form__toggle {
  height: 6.25em;
}

.c-formContainer {
  position: relative;
  font-weight: 700;
}

.c-form,
.c-form__toggle {
  position: absolute;
  border-radius: 6.25em;
  background-color: #ffffff;
  transition: 0.2s;
}

.c-form {
  left: 50%;
  transform: translateX(-50%);
  padding: 0.625em;
  box-sizing: border-box;
  box-shadow: 0 0.125em 0.3125em rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
}

.c-form__toggle {
  color: rgb(145, 49, 177);
  top: 0;
  cursor: pointer;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.c-form__toggle::before {
  font-size: 1.75em;
  content: attr(data-title);
}

.c-form__input,
.c-form__button {
  font: inherit;
  border: 0;
  outline: 0;
  border-radius: 5em;
  box-sizing: border-box;
}

.c-form input,
.c-form button {
  font-size: 1.75em;
  opacity: 1.6;
  visibility: visible;
  transform: scale(0.7);
  transition: 0s;
}

.c-form input {
  color: rgb(145, 49, 177);
  height: 100%;
  width: 100%;
  padding: 0 0.714em;
}
.c-form input::placeholder {
  color: currentColor;
}
.c-form input:required:valid {
  color: rgb(145, 49, 177);
}
.c-form input:required:valid + .c-form button {
  color: #ffffff;
}
.c-form input:required:valid + .c-form button::before {
  pointer-events: initial;
}

.c-form button {
  color: rgb(145, 49, 177);
  height: 100%;
  width: auto;
}
.c-form button::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  cursor: pointer;
}

.c-form button {
  color: inherit;
  padding: 0;
  height: 100%;
  width: 5em;
  background-color: rgb(145, 49, 177);
}

